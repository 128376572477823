/**
 * Interface to HFDM.
 */

// A function that returns the interface to the Hfdm extension
export let getHFDM;
// A function that register this worker
export let register;

if (BUILD_FLAG__IS_WORKER) {
    // In workers we use a MessageChannel to communicate with the main thread to get
    // property values. We don't support getting thumbnails or the DS manifest on a worker
    let hfdmPort;
    let requestId = 0;
    const requests = {};
    
    /**
     * Get the value of a property
     * @param {string} branchUrn - The urn for the branch with the property
     * @param {string[]} path - The path from the branch root to the property
     * @param {function} onSuccess - A function that takes a single string parameter that is the id.
     * @param {function} onFailure - A function that takes an integer status code, and a string status, which together represent
     *                               the response returned if the request is unsuccessful, and a third data argument, which
     *                               has more information about the failure.  The data is a dictionary that minimally includes
     *                               the url, and an exception if one was raised.
    */
    function getValueFromMainThread(branchUrn, path, onSuccess, onFailure) {
        // If the port isn't initialized then this is an error
        if (!hfdmPort) {
            onFailure(0, "Worker thread not properly initialized", { });
            return;
        }

        // Remember the request
        requests[++requestId] = [onSuccess, onFailure];

        // Ask the main thread for the value
        hfdmPort.postMessage({
            requestId: requestId,
            branchUrn,
            path
        });
    }

    /**
     * Message handler for "HFDM_SET_PORT" operation
     * @param {*} data 
     */
    function setHfdmPort(data) {
        // Set the port
        hfdmPort = data.port;

        // Set the message handler for property values from the main thread
        hfdmPort.onmessage = function(ev) {
            try {
                // Get the request this reply was sent for, and then delete it
                const request = requests[ev.data.requestId];
                delete requests[ev.data.requestId];
                if (request) {
                    // Return the value or an error
                    if (ev.data.id) {
                        request[0](ev.data.id);
                        return;
                    } else if (ev.data.error) {
                        request[1](ev.data.error.status, ev.data.error.statusText, ev.data.error.moreInfo);
                        return;
                    }
                }
                onFailure(0, "Unspecified error getting binary data id", { });
            } catch (e) {
                onFailure(0, "Unspecified error getting binary data id", { exception: e });
            }
        }
    }

    // Register the HFDM_SET_PORT handler
    register = function(workerMain) {
        workerMain.register("HFDM_SET_PORT", { doOperation: setHfdmPort });
    }

    // We are on a worker thread so there isn't an extension. Make some
    // dummy functions that will error except for binarey data and use
    // the hfdm channel to get the id from the main thread.
    const workerHFDM = {
        getDSManifest: function(parsedUrl, onSuccess, onFailure, options) {
            onFailure(0, "Manifest not available on worker thread", { url: parsedUrl.href });
        },
        getThumbnailId: function(parsedUrl, onSuccess, onFailure, options) {
            onFailure(0, "Thumnails not available on worker thread", { url: parsedUrl.href });
        },
        getValueAtPath: function(branchUrn, path, onSuccess, onFailure, options) {
            getValueFromMainThread(branchUrn, path, onSuccess, onFailure, options);
        }
    };
    getHFDM = function() {
        return workerHFDM;
    };
} else {
    // On the main thread, we just get the HFDM extension namespace 
    getHFDM = function() {
        return Autodesk.Viewing.Extensions.HFDM;
    };
}
