
import { Extension } from "../../src/application/Extension";
import { isIOSDevice, isIE11, isAndroidDevice } from "../../src/compat";
import { Button } from "../../src/gui/controls/Button";
import { stringToDOM } from "../../src/globals";
import { logger } from "../../src/logger/Logger";
import { EXPLODE_CHANGE_EVENT } from "../../src/application/EventTypes";

    /**
     * 
     * 
     * Use its `activate()` method to enable the explode UI.
     * 
     * The extension id is: `Autodesk.Explode`
     * 
     * @example
     *   viewer.loadExtension('Autodesk.Explode')
     * 
     * @memberof Autodesk.Viewing.Extensions
     * @alias Autodesk.Viewing.Extensions.ExplodeExtension
     * @see {@link Autodesk.Viewing.Extension} for common inherited methods.
     * @constructor
     */
    export function ExplodeExtension(viewer, options) {
        Extension.call(this, viewer, options);
        this.viewer = viewer;
        this.options = options;
        this.name = "explode";

        this.deactivate = this.deactivate.bind(this);
        this._onExplode = this._onExplode.bind(this);

        // these below are all assinged in _createUI()
        this._slider = null; 
        this._explodeButton = null;
        this._explodeSubmenu = null;
        this._tooltip = null;
    }
    ExplodeExtension.prototype = Object.create(Extension.prototype);
    ExplodeExtension.prototype.constructor = ExplodeExtension;

    var proto = ExplodeExtension.prototype;

    /**
     * Adds an explode button to the toolbar. When pressed, a slider
     * will be shown which allows changing the model's explosion value.
     * 
     * @memberof Autodesk.Viewing.Extensions.ExplodeExtension
     * @alias Autodesk.Viewing.Extensions.ExplodeExtension#load
     */
    proto.load = function() {
        // No matter whether the UI initializes, the extension always loads.
        return true;
    };

    /**
     * Removes the explode button from the toolbar and deactivates the explode state.
     * 
     * @memberof Autodesk.Viewing.Extensions.ExplodeExtension
     * @alias Autodesk.Viewing.Extensions.ExplodeExtension#unload
     */
    proto.unload = function() {

        this.deactivate();
        _destroyUI(this);

        return true;
    };

    /**
     * Invoked by the viewer when the toolbar UI is available.
     *
     * @param {Autodesk.Viewing.UI.ToolBar} toolbar - toolbar instance.
     *
     * @alias Autodesk.Viewing.Extensions.ExplodeExtension#onToolbarCreated
     */
    proto.onToolbarCreated = function(toolbar) {
        _createUI(this, toolbar);
    };

    /**
     * Displays the explode UI.
     * 
     * @memberof Autodesk.Viewing.Extensions.ExplodeExtension
     * @alias Autodesk.Viewing.Extensions.ExplodeExtension#activate
     */
    proto.activate = function() {
        if (this.isActive())
            return true;

        this._explodeSubmenu.style.display = "";
        this._explodeButton.setState(Button.State.ACTIVE);
        this._tooltip.style.display = "none";
        
        // Sync slider with viewer's explode value
        let lmvExplodeValue = this.viewer.impl.getExplodeScale();
        this._slider.value = lmvExplodeValue;

        // Update UI only when the event is fired
        this.viewer.addEventListener(EXPLODE_CHANGE_EVENT, this._onExplode);

        return true;
    };

    /**
     * Hides the explode UI and resets explode slider.
     * 
     * @memberof Autodesk.Viewing.Extensions.ExplodeExtension
     * @alias Autodesk.Viewing.Extensions.ExplodeExtension#deactivate
     */
    proto.deactivate = function() {
        if (!this.isActive())
            return true;
        
        this._explodeButton.setState(Button.State.INACTIVE);
        _hideSlider(this);
        
        this.viewer.explode(0); // Resets the UI slider via event handler.

        // Update UI only when the event is fired
        this.viewer.removeEventListener(EXPLODE_CHANGE_EVENT, this._onExplode);
    
        return true;
    };

    /**
     * @returns {boolean} true is the explode UI is visible.
     *
     * @memberof Autodesk.Viewing.Extensions.ExplodeExtension
     * @alias Autodesk.Viewing.Extensions.ExplodeExtension#isActive
     */
    proto.isActive = function() {
        if (!this._explodeSubmenu)
            return false;
        return this._explodeSubmenu.style.display !== 'none';
    };

    /**
     * @private
     */
    proto._onExplode = function(event) {
        this._slider.value = event.scale;
    };

    /**
     * Enable / Disable the explode button & slider.
     * Doesn't affect the state of the explode scale itself.
     *
     * @param {boolean} enable - enable / disable the UI.
     * 
     * @memberof Autodesk.Viewing.Extensions.ExplodeExtension
     * @alias Autodesk.Viewing.Extensions.ExplodeExtension#setUIEnabled
     */
    proto.setUIEnabled = function(enable) {
        if (this._explodeButton) {
            if (enable) {
                // Re-enable button
                this._explodeButton.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);

                if (this._wasActive) {
                    this.activate();
                }
            } else {
                this._wasActive = this.isActive();

                // We don't just use deactivate() because you want to keep the explode scale.
                _hideSlider(this);

                // Disable button
                this._explodeButton.setState(Autodesk.Viewing.UI.Button.State.DISABLED);
            }
        }
    };

/**
 * Create toolbar button, explode slider and all other UI.
 *
 * @private
 */
function _createUI(ext, toolbar) {

    const avu = Autodesk.Viewing.UI;
    const viewer = ext.viewer;

    var explodeButton = new Button('toolbar-explodeTool');
    explodeButton.setIcon("adsk-icon-explode");
    explodeButton.setToolTip("Explode model");
    viewer.modelTools.addControl(explodeButton);

    var htmlString = '<div class="docking-panel docking-panel-container-solid-color-b explode-submenu" style="display:none"><input class="explode-slider" type="range" min="0" max="1" step="0.01" value="0"/></div>';
    let explodeSubmenu = stringToDOM(htmlString);

    var parentDom;
    const _document = ext.getDocument();
    if (isIOSDevice()) {
        // hack fix for iOS bug
        // range input not draggable when nested under button
        parentDom = _document.querySelector("#toolbar-explodeTool").parentNode;
        explodeSubmenu.classList.add("ios");
    }
    else if (isAndroidDevice()) {
        // hack fix for Android bug
        // range input not draggable when nested under button
        parentDom = _document.querySelector("#toolbar-explodeTool").parentNode;
        explodeSubmenu.classList.add("android");
    }
    else {
        parentDom = explodeButton.container;
    }
    parentDom.appendChild(explodeSubmenu);

    var slider = explodeSubmenu.querySelector(".explode-slider");
    slider.addEventListener(isIE11 ? "change" : "input", function(event) {
        viewer.explode(slider.value);
    });

    if (isIE11) {
        // In IE11, the input type=range has a weird default layout...
        slider.style['padding-top'] = '0';
        slider.style['padding-bottom'] = '0';
        slider.style['margin-top'] = '10px';
    }

    explodeSubmenu.onclick = function (event) {
        event.stopPropagation();
    };

    // hack to disable tooltip
    var tooltip = explodeButton.container.querySelector(".adsk-control-tooltip");

    explodeButton.onClick = function(event) {
        
        if (ext.isActive()) {
            ext.deactivate();
        } else {
            ext.activate();
            
            // Track tool change only when interacted by the end user.
            logger.track({category: 'tool_changed', name: 'explode'});
        }
    };

    // Keep references
    ext._slider = slider;
    ext._explodeButton = explodeButton;
    ext._explodeSubmenu = explodeSubmenu;
    ext._tooltip = tooltip;

    // backwards compatibility references
    viewer.explodeSlider = slider;
    viewer.explodeSubmenu = explodeSubmenu;
}

/**
 * @param {Object} ext - The extension
 * @private
 */
function _destroyUI(ext) {

    let viewer = ext.viewer;

    // early bail out if the UI hasn't actually been initialized.
    if (!ext._slider) {
        return;
    }

    if (ext._explodeButton) {
        ext._explodeButton.removeFromParent();
    }

    // Reset references
    ext._slider = null;
    ext._explodeButton = null;
    ext._explodeSubmenu = null;
    ext._tooltip = null;

    // Reset backwards compatibility references
    viewer.explodeSlider = null;
    viewer.explodeSubmenu = null;
}

/**
 * @param {Object} ext - The extension
 * @private
 */
function _hideSlider(ext) {
    ext._slider.parentNode.style.display = "none";
    ext._tooltip.style.display = "";
}
