/**
 * Override worker creater functions for HFDM
 * The overrides are used to extablish the property value channel for a worker
 */

/**
 * Setup the MessageChannel for a worker
 * @param {Worker} worker The worker
 */
export function establishChannel(worker) {
    // Create the channel
    const channel = new MessageChannel();

    // Override the terminate method to close the ports on the channel
    const _terminate = worker.terminate;
    worker.terminate = function terminate() {
        channel.port1.close();
        channel.port2.close();
        _terminate.call(this);
    };

    // Setup the handler for property value requests
    channel.port1.onmessage = function(e) {
        try {
            const data = e.data;
            if (data) {
                // Get the value and return it or an error
                Autodesk.Viewing.Extensions.HFDM.getValueAtPath(data.branchUrn, data.path, function(id) {
                    channel.port1.postMessage({ requestId: e.data.requestId, id });
                }, function(status, statusText, moreInfo) {
                    channel.port1.postMessage({ requestId: e.data.requestId, error: { status, statusText, moreInfo }});
                });
            }
        } catch (ex) {
            channel.port1.postMessage({ error: { status: 0, statusText: ex.toString(), moreInfo: { exception: ex } } })
        }
    };

    // Setup the channel in the worker
    worker.postMessage({ operation: "HFDM_SET_PORT", port: channel.port2 }, [channel.port2]);
    return worker;
};

// Override the WorkerCreator export
export function initialize(exports) {
    // Create the new export object for WorkerCreator
    const exp = Object.assign({}, exports);

    // Override createWorker
    const _createWorker = exports.createWorker;
    exp.createWorker = function createWorker() {
        // Establish channel for the worker
        return establishChannel(_createWorker());
    };

    // Override createWorkerWithIntercept
    const _createWorkerWithIntercept = exports.createWorkerWithIntercept;
    exp.createWorkerWithIntercept = function createWorkerWithIntercept() {
        // Establish channel for the worker
        return establishChannel(_createWorkerWithIntercept());
    };

    return exp;
};
