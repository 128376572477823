
import { getParameterByName, getScript, getParameterByNameFromPath } from "./globals";
import { loadDependency } from "./load-dependency";
import { logger } from "./logger/Logger";
import { endpoint, setEnv, getEnv, setOfflineResourcePrefix, setOffline } from "./file-loaders/net/endpoints";
import { getGlobal, isNodeJS, disableDocumentTouchSafari } from "./compat";
import { initWorkerScript } from "./file-loaders/main/WorkerCreator";
import { ViewingService } from "./file-loaders/net/Xhr";
import { initializeLocalization } from "./globalization/i18init";
import { theExtensionManager } from "./application/ExtensionManager";

var global = getGlobal();
const _window = global;

var _token = {
    accessToken : null,
    getAccessToken : null,
    tokenRefreshInterval : null
};

export let token = _token;

let WEBGL_HELP_LINK = null;
export function getWebGLHelpLink() {
    return WEBGL_HELP_LINK;
}


    var LmvEndpoints = {
        local: {
            RTC:        ['https://rtc-dev.api.autodesk.com:443', 'https://lmv.autodesk.com:443'] //port # is required here.
        },
        dev: {
            RTC:        ['https://rtc-dev.api.autodesk.com:443', 'https://lmv.autodesk.com:443']
        },
        stg: {
            RTC:        ['https://rtc-stg.api.autodesk.com:443', 'https://lmv.autodesk.com:443']
        },
        prod: {
            RTC:        ['https://rtc.api.autodesk.com:443', 'https://lmv.autodesk.com:443']
        }
    };

    var DevApiUrls = {
        local: "",
        dev: "https://developer-dev.api.autodesk.com",
        stg: "https://developer-stg.api.autodesk.com",
        prod: "https://developer.api.autodesk.com"
    };

    var FluentApiUrls = {
        dev: "https://us.otgs-dev.autodesk.com",
        stg: "https://us.otgs-stg.autodesk.com",
        prod: "https://us.otgs.autodesk.com"
    };

    const HFDMApiUrls = {
        local: "",
        dev:   "https://developer-dev.api.autodesk.com/hfdm",
        stgLegacy:   "https://developer-stg.api.autodesk.com/hfdm",
        stg:   "https://developer-stg.api.autodesk.com/hfdm-soc2",
        stb:   "https://developer-stg.api.autodesk.com/hfdm-stable",
        prodLegacy:  "https://developer.api.autodesk.com/hfdm",
        prod:  "https://developer.api.autodesk.com/hfdm-soc2",
    };

    export let EnvironmentConfigurations = {
        Local: {
            ROOT:       '',
            LMV:        LmvEndpoints["local"]
        },
        Development: {
            ROOT:       DevApiUrls["dev"],
            LMV:        LmvEndpoints["dev"],
            bubbleManifest: true
        },
        Staging: {
            ROOT:       DevApiUrls["stg"],
            LMV:        LmvEndpoints["stg"],
            bubbleManifest: true
        },
        Production: {
            ROOT:       DevApiUrls["prod"],
            LMV:        LmvEndpoints["prod"],
            bubbleManifest: true
        },
        AutodeskDevelopment: {
            ROOT:       DevApiUrls["dev"],
            LMV:        LmvEndpoints["dev"]
        },
        AutodeskStaging: {
            ROOT:       DevApiUrls["stg"],
            LMV:        LmvEndpoints["stg"]
        },
        AutodeskProduction: {
            ROOT:       DevApiUrls["prod"],
            LMV:        LmvEndpoints["prod"]
        },
        FluentDev: {
            ROOT:       FluentApiUrls["dev"],
            LMV:        LmvEndpoints["dev"]
        },
        FluentStaging: {
            ROOT:       FluentApiUrls["stg"],
            LMV:        LmvEndpoints["stg"]
        },
        FluentProduction: {
            ROOT:       FluentApiUrls["prod"],
            LMV:        LmvEndpoints["prod"]
        },
        HFDMDevelopment: {
            ROOT:   HFDMApiUrls["dev"],
            LMV:    LmvEndpoints["dev"]
        },
        HFDMStagingLegacy: {
            ROOT:   HFDMApiUrls["stgLegacy"],
            LMV:    LmvEndpoints["stg"]
        },
        HFDMStaging: {
            ROOT:   HFDMApiUrls["stg"],
            LMV:    LmvEndpoints["stg"]
        },
        HFDMStable: {
            ROOT:   HFDMApiUrls["stb"],
            LMV:    LmvEndpoints["stg"]
        },
        HFDMProductionLegacy: {
            ROOT:   HFDMApiUrls["prodLegacy"],
            LMV:    LmvEndpoints["prod"]
        },
        HFDMProduction: {
            ROOT:   HFDMApiUrls["prod"],
            LMV:    LmvEndpoints["prod"]
        }
    };


    export function initializeEnvironmentVariable(options) {
        var env;

        // Use the enviroment that was explicitly specified.
        //
        if (options && options.env) {
            env = options.env;
        }

        // If not available, check if the environment was specified in the query parameters.
        //
        if (!env) {
            env = getParameterByName("env");
        }
        
        setOfflineResourcePrefix((options && options.offlineResourcePrefix) || "");

        setOffline(options && options.offline === "true");

        // If still not available, try to resolve the environment based on the url.
        //
        if (!env) {
            switch (_window.location.hostname) {
                case "developer-dev.api.autodesk.com" :
                    env = 'AutodeskDevelopment';
                    break;
                case "developer-stg.api.autodesk.com" :
                    env = 'AutodeskStaging';
                    break;
                case "developer.api.autodesk.com" :
                    env = 'AutodeskProduction';
                    break;

                case "localhost.autodesk.com" :
                    env = 'Local';
                    break;
                case "" : // IP addresses on Chrome.
                    env = 'Local';
                    break;
                case "127.0.0.1" :
                    env = 'Local';
                    break;
                default:
                    env = 'AutodeskProduction';
            }
        }

        setEnv(env);

        if (typeof window !== "undefined") {
            logger.info("Host name : " + window.location.hostname);
        }
        logger.info("Environment initialized as : " + env);
    };

    export function initializeResourceRoot(options) {
        //Derive the root for static viewer resources based on the
        //location of the main viewer script
        var libList = [
            "viewer3D.js",	 
            "viewer3D.min.js",
            "viewerCE.js",
            "viewerCE.min.js"
        ];
        if (options && options.hasOwnProperty('libraryName'))
            libList.push(options.libraryName);

        var root;
        var scriptUrl;
        var hasVersionInURL = false;

        // TODO_NOP: this doesn't work for Polymer / Web Components
        for (var i=0; i<libList.length; i++) {
            var script = getScript(libList[i]);
            scriptUrl = script ? script.src : "";
            var idx = scriptUrl.indexOf(libList[i]);
            if (idx >= 0) {
                root = scriptUrl.substr(0, idx);
                if (scriptUrl.indexOf('&v=', idx) > 0 || scriptUrl.indexOf('?v=', idx) > 0) {
                    hasVersionInURL = true;
                }
                break;
            }
        }

        global.LMV_RESOURCE_ROOT = root || global.LMV_RESOURCE_ROOT;

        // Transfer version from URL-param into viewer/path/ now that Forge supports it.
        if (hasVersionInURL) {

            // However, only do this when the viewer code is being downloaded from Forge CDN
            var patchResourceRoot = false;
            var checkEnvs = ['dev', 'stg', 'prod'];
            for (var i=0; i<checkEnvs.length; ++i) {
                var theEnv = DevApiUrls[checkEnvs[i]];
                if (global.LMV_RESOURCE_ROOT.indexOf(theEnv) !== -1) {
                    patchResourceRoot = true;
                    break;
                }
            }

            if (patchResourceRoot) {
                // Embed the version into the base URL.
                global.LMV_RESOURCE_ROOT += global.LMV_VIEWER_VERSION + '/';
            }
        }
    }

    export function initializeServiceEndPoints(options) {

        // Get endpoint.
        var endp = options.endpoint;
        if (!endp) {
            var config = EnvironmentConfigurations[getEnv()];
            endp = config['ROOT'];
        }

        // Get endpoint api.
        var api = options.api || endp.ENDPOINT_API_DERIVATIVE_SERVICE_V2;

        endpoint.setEndpointAndApi(endp, api);

        if (isNodeJS())
            return;

        initializeResourceRoot(options);

        if (api === endpoint.ENDPOINT_API_HFDM) {
            // Forge HFDM extension code to download before continuing. 
            return new Promise((resolve)=>{
                var resourcePath = theExtensionManager.getExternalPath('Autodesk.HFDM');
                loadDependency(undefined, resourcePath, function() {
                    resolve();
                });
            });
        }
        
        return Promise.resolve();
    }


    //By now, the use of cookie is Fluent-specific only
    export function refreshCookie(token, onSuccess, onError) {

        // rawGet doesn't accept undefined onSuccess callbacks
        onSuccess = onSuccess || function() {};
        ViewingService.rawGet(endpoint.getApiEndpoint(), null, "/auth/settoken", onSuccess, onError,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                withCredentials: true,
                postData: "access-token=" + token
            }
        );
    }

    // Refresh the token in request header, in case that the third party cookie is disabled
    export function refreshRequestHeader(token) {

        endpoint.HTTP_REQUEST_HEADERS["Authorization"] = "Bearer " + token;

    }

    export function refreshToken(in_tokenStr, onSuccess, onError) {

        // Store the token, it will be used when third-party cookies are disabled
        _token.accessToken = in_tokenStr;

        //TODO: Fluent. Currently we need to use the cookie based approach,
        //until the server is fixed to respond to CORS pre-flight requests
        //with Authorization header.
        // At the beginning, try to store the token in cookie
        if (endpoint.getUseCookie()) {

            var wrapError = function(e) {
                logger.warn("Failed to set token in cookie. Will use header instead.");
                endpoint.setUseCookie(false);
                refreshRequestHeader(in_tokenStr);
                onError && onError(e);
            };

            refreshCookie(in_tokenStr, onSuccess, wrapError);
        } else {
            refreshRequestHeader(in_tokenStr);
            onSuccess && onSuccess();
        }
    }

    function initializeAuthPromise(options) {
        return new Promise((resolve)=>{
            initializeAuth(resolve, options);
        });
    }

    export function initializeAuth(onSuccessCallback, options) {

        var shouldInitializeAuth = options ? options.shouldInitializeAuth : undefined;
        if (shouldInitializeAuth === undefined) {
            var p = getParameterByName("auth");
            shouldInitializeAuth = (p.toLowerCase() !== "false");
        }

        //Skip Auth in case we are serving the viewer locally
        if (getEnv() == "Local" || !shouldInitializeAuth) {
            setTimeout(onSuccessCallback, 0);
            endpoint.setUseCredentials((typeof options.useCredentials !== "undefined") ? options.useCredentials : false);
            return;
        }

        endpoint.setUseCredentials((typeof options.useCredentials !== "undefined") ? options.useCredentials : true);
        endpoint.setUseCookie(options.useCookie);

        //Must zero this out every time the initializer is called -- which could happen
        //several times with the same globally loaded LMV script/module
        if (_token.tokenRefreshInterval) {
            clearTimeout(_token.tokenRefreshInterval);
            _token.tokenRefreshInterval = null;
        }

        var accessToken;

        function onGetAccessToken(token /* access token value. */, expire /* expire time, in seconds. */) {
            accessToken = token;

            var isFirstTimeInit = !_token.tokenRefreshInterval;

            if (!isFirstTimeInit) {
                refreshToken(accessToken);
            } else {
                //The goal of onSuccessCallback is to continue viewer initialization.
                //In case refreshToken fails, we don't really want to block viewer initialization
                //(in order to be able to display error messages later on, or retry the token refresh, etc.)
                //This is why we use the same callback regardless of result.
                refreshToken(accessToken, onSuccessCallback, onSuccessCallback);
            }

            if (typeof expire !== "number")
                expire = 3599;

            var interval = expire - 5; // Refresh 5 seconds before token expire.
            if (interval <= 0) {
                // We can't get a precise upper bound if the token is such a short lived one (expire in a minute),
                // so just use the original one.
                interval = expire;
            }

            _token.tokenRefreshInterval = setTimeout(function() {options.getAccessToken(onGetAccessToken);}, interval * 1000);
        }

        if (options && options.getAccessToken) {
            _token.getAccessToken = options.getAccessToken;

            accessToken = options.getAccessToken(onGetAccessToken);

            //Backwards compatibility with the old synchronous API
            if (typeof accessToken === "string" && accessToken) {
                refreshToken(accessToken, onSuccessCallback);
            }

        } else if (options && options.accessToken) {
            accessToken = options.accessToken;
            refreshToken(accessToken, onSuccessCallback);
        } else {
            accessToken = getParameterByName("accessToken");
            if (!accessToken) {
                logger.error("No access token is provided, but authorization requested. This is a problem.");
            }
            refreshToken(accessToken, onSuccessCallback);
        }

    }

    export function initializeLogger(options) {

        logger.initialize(options);

        var logLevel = getParameterByName("logLevel");
        if (logLevel) {
            logger.setLevel(parseInt(logLevel));
        }
    }

    function initializeCDN(options) {

        if (options && options.env === 'Local')
            return;

        if (!endpoint.getCdnRedirectUrl())
            return;

        ViewingService.rawGet(endpoint.getCdnRedirectUrl(), null, null,
            function onSuccess(res) {
                if (res && res.length) {
                    endpoint.setCdnUrl(res);
                    logger.info("CDN_ROOT is: " + res);
                }
            },
            function onError() {
            },
            {
                withCredentials: false,
                responseType: "text"
            }
        );
    }

    /**
     * Static method for initializing the viewer runtime. Models must be loaded after this
     * step is completed.
     *
     * Includes:
     *  - End points of cloud services the viewer uses, like Model Derivative
     *  - Authentication and authorization cookie settings on the client side
     *  - Misc runtime environment variables and global viewer configurations parameters
     *
     * @alias Autodesk.Viewing#Initializer
     *
     * @param {object} options - The options object contains configuration parameters used to do initializations. If no
     * access token or authentication callback is provided, the Initializer will fall back
     * on an access token provided in the URL query string, or a previous access token stored in
     * the cookie cache, if available.
     * @param {string} [options.env] - Either "AutodeskProduction", "AutodeskStaging", or "AutodeskDevelopment". 
     * "Local" can be used to avoid attaching athentication headers to XHR requests.
     * @param {string} [options.api] - "modelDerivativeV2" or "derivativeV2" for US data center,
     * or use "derivativeV2_EU" for European data center.
     * @param {function} [options.getAccessToken] - An function that provides an access token asynchronously.
     * The function signature is `getAccessToken(onSuccess)`, where onSuccess is a callback that getAccessToken
     * function should invoke when a token is granted, with the token being the first input parameter for the
     * onSuccess function, and the token expire time (in seconds) being the second input parameter for the
     * function. Viewer relies on both getAccessToken and the expire time to automatically renew token, so
     * it is critical that getAccessToken must be implemented as described here.
     * @param {string} [options.accessToken] - An access token. Not needed when `options.getAccessToken` is provided.`
     * @param {string} [options.webGLHelpLink] - A link to a help page on webGL if it's disabled.
     * @param {string} [options.language] - Preferred language code as defined in RFC 4646, such as "en", "de", "fr", etc.
     * If no language is set, viewer will pick it up from the browser. If language is not as defined in RFC,
     * viewer will fall back to "en" (English) but the behavior is undefined.
     * @param {function} callback - A method the client executes when initialization is finished.
     *
     * @example
     *  var options = {
     *     env: "AutodeskProduction",
     *     language: "en",
     *     getAccessToken: function(onSuccess) {
     *         // TODO: Get actual forge token and expiration time.
     *         var accessToken = 'your_access_token';
     *         var expirationTimeSeconds = 5 * 60; // 5 minutes
     *         onSuccess(accessToken, expirationTimeSeconds);
     *     }
     *  };
     *  var myCallback = function() {
     *     console.log("initialization complete, creating the viewer...");
     *  };
     *  Autodesk.Viewing.Initializer(options, myCallback);
     *
     */
    export function Initializer(options, callback) {

        if (isNodeJS()) {

            initializeEnvironmentVariable(options);
            initializeServiceEndPoints(options);
            initializeLogger(options);
            initializeCDN(options);
            initializeAuth(callback, options);
            return;
        }

        // Web //
        if (options.webGLHelpLink)
            WEBGL_HELP_LINK = options.webGLHelpLink;

        //Load Promise (IE11), and other delay loaded dependencies then continue initialization
        loadDependency('Promise', 'es6-promise.min.js', function() { // Usually a no-op
            init();
        }, null, 'es6-promise-polyfill');

        function init() {

            initializeEnvironmentVariable(options);
            var apiProm = initializeServiceEndPoints(options);
            initializeLogger(options);
            initializeCDN(options);
            initializeLocalization(options);
            disableDocumentTouchSafari();

            //Kick off a request for the web worker script
            initWorkerScript();

            var authProm = initializeAuthPromise(options);

            Promise.all([apiProm, authProm]).then(callback);
        }
    }

    /**
     * Use this method to shutdown the application properly, and to free up the memory
     * @alias Autodesk.Viewing#shutdown
     */
    export function shutdown() {

        logger.shutdown();

        let propWorker = Autodesk.Viewing.Private.getPropWorker();
        if(propWorker) {
            propWorker.clearAllEventListenerWithIntercept();
            propWorker.terminate();
        }

    }